import mainAudio from '../assets/audio/mainAudio.mp4'
import gsap from 'gsap'
// import ticks from '../assets/audio/tick.mp4'
const audioMain = new Audio(mainAudio) // audioの作成
window.onload = function(){
   
    audioMain.load(); // audioの読み込み
    audioMain.play()
    audioMain.volume = 0.5
    audioMain.loop = true
}




const audioAnim = gsap.to('.audioBtn', 1, {
    scale: 1.2,
    ease: 'Expo.out',
    repeat: -1,
    yoyo: true,
    color: 'rgba(0,0,0,.3)'
})


const mainAudioPlay = document.querySelectorAll('#audioMain')
for(let i =0;i<mainAudioPlay.length; i++){
mainAudioPlay[i].addEventListener('click', () => {
    if (!audioMain.paused) {
        audioMain.pause()
        audioAnim.pause(0)
    } else {
        audioMain.play()
        audioAnim.play()
    }
})
}


const entranceSound = document.querySelector('.soundMark')
gsap.to(entranceSound,1,{
    scale: 1.2,
    ease: 'Expo.out',
    repeat: -1,
    yoyo: true,
    opacity:1
})
